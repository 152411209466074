import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      // {
      //   path: '/',
      //   redirect: '/homepage'
      // },
      {
        path: '/pc_home',
        name: 'Homepage',
        component: () => import('../components/Homepage.vue')
      },
      {
        path: '/space',
        name: 'Space',
        component: () => import('../components/Space.vue')
      },
      {
        path: '/study',
        name: 'Study',
        component: () => import('../components/Study.vue')
      },
      {
        path: '/support',
        name: 'Support',
        component: () => import('../components/Support.vue')
      },
      {
        path: '/club',
        name: 'Club',
        component: () => import('../components/Club.vue')
      },
    ]
  },
  {
    path: '/mb_home',
    name: 'MHome',
    component: () => import('../moxi/MHome.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/midArea',
    name: 'MidArea',
    component: () => import('../components/MidArea.vue')
  },
  {
    path: '/footer',
    name: 'Footer',
    component: () => import('../components/Footer.vue')
  },
  {
    path: '**', // 错误路由
    redirect: '/' //重定向  
  }

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router