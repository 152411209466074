<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  data() {
    return {
      screenWidth: window.screen.width,
      timer: ''
    }
  },
  mounted() {
    // const that = this
    // window.onresize = () => {
    //   return (() => {
    //     that.screenWidth = window.screen.width
    //   })()
    // }
    if (this.isMobile()) {
      this.$router.replace('/mb_home')
    } else {
      this.$router.replace('/pc_home')
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
