<template>
  <div class="home">
    <div class="first-header">
      <div class="firstImage"></div>
      <el-menu
        :default-active="activeIndex"
        router
        class="first-page-menu"
        mode="horizontal"
        background-color="rgba(0, 0, 0, 0)"
        text-color="#FFFEFE"
        active-text-color="#7FE96E"
      >
        <el-menu-item index="/pc_home">首页</el-menu-item>
        <el-menu-item index="/space">空间</el-menu-item>
        <el-menu-item index="/study">学习</el-menu-item>
        <el-menu-item index="/support">支持</el-menu-item>
        <!-- <el-menu-item index="/club">社区</el-menu-item> -->
        <el-menu-item index="">
          <a
            href="https://flarum.yangwudongxi.com/"
            target="_blank"
            rel="noopener noreferrer"
            style="text-decoration: none"
            ><p class="clubHeight">社区</p></a
          >
        </el-menu-item>
      </el-menu>
    </div>
    <div class="first-main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      activeIndex: '/pc_home',
      activeIndex2: '1'
    }
  },
  components: {},
  create() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgb(27, 31, 35); */
}
.first-header {
  position: absolute;
  width: 1920px;
  top: 20px;
  font-size: 24px;
  z-index: 10;
}

.firstImage {
  position: absolute;
  top: 13px;
  left: 41px;
  width: 40px;
  height: 40px;
  background-image: url('/img/start.png');
  background-size: 100% 100%;
}

.first-page-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.first-page-menu.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu-item {
  padding: 0 25px;
  font-size: 24px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
}
.el-menu-item.is-active {
  font-weight: bold;
}

.clubHeight {
  line-height: 60px;
}
.studyStyle {
  font-size: 24px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  line-height: 60px;
}
</style>
